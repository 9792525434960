.App {
    font-family: sans-serif;
    text-align: center;
    min-height: 700px;
    /*background-color: paleturquoise;*/
    margin: 0;
    padding: 0px;
    width: 100%;
  }
  /* FONT IMPORTS */

  @import url(https://fonts.googleapis.com/css?family=Open + Sans:300, 400, 700);
  @import url(https://fonts.googleapis.com/icon?family=Material + Icons);

  .icon {
    font-family: "Material Icons", serif;
    font-style: normal;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
  }

  /* VARIABLES */

  :root {
    --main-color: #1a8fff;
    --text-color: #777;
    --text-color-light: #ccc;
    --border-color: #eee;
    --bg-color: #f9f9f9;
    --neutral-color: #fff;
  }

  /* GENERAL */

  * {
    box-sizing: border-box;
  }

  body {
    font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5;
    color: var(--text-color);
    background: var(--bg-color);
    position: relative;
  }

  header {
    display: block;
    width: 100%;
    padding: 1.75em 0;
    border-bottom: 1px solid var(--border-color);
    background: var(--neutral-color);
  }

  header #logo {
    font-size: 175%;
    text-align: center;
    color: var(--main-color);
    line-height: 1;
  }

  header #logo .icon {
    padding-right: 0.25em;
  }

  main {
    display: block;
    margin: 0 auto;
    margin-top: 5em;
    max-width: 50em;
  }

  /* GRID */

  .calendar-month .row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .calendar-month .row-middle {
    align-items: center;
  }

  .calendar-month .col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .calendar-month .col-start {
    justify-content: flex-start;
    text-align: left;
  }

  .calendar-month .col-center {
    justify-content: center;
    text-align: center;
  }

  .calendar-month .col-end {
    justify-content: flex-end;
    text-align: right;
  }

  /* Calendar */

  .calendar-month {
    display: block;
    position: relative;
    width: 100%;
    background: var(--neutral-color);
    border: 1px solid var(--border-color);
    font-size: 1em;
  }
  @media (max-width: 1280px) {
    .calendar-month {
        font-size: 0.35em;
      }
  }

  .calendar-month .header {
    /*text-transform: uppercase;*/
    font-weight: 700;
    font-size: 115%;
    padding: 1.5em 0;
    border-bottom: 1px solid var(--border-color);
  }

  .calendar-month .header .icon {
    cursor: pointer;
    transition: 0.15s ease-out;
  }

  .calendar-month .header .icon:hover {
    /*transform: scale(1.75);*/
    transition: 0.25s ease-out;
    color: var(--main-color);
  }

  .calendar-month .header .icon:first-of-type {
    margin-left: 1em;
  }

  .calendar-month .header .icon:last-of-type {
    margin-right: 1em;
  }

  .calendar-month .days {
    text-transform: uppercase;
    font-weight: 400;
    color: var(--text-color-light);
    font-size: 70%;
    padding: 0.75em 0;
    border-bottom: 1px solid var(--border-color);
  }

  .calendar-month .body .cell {
    position: relative;
    /*height: 7.5em;*/
    border-right: 1px solid var(--border-color);
    overflow: hidden;
    cursor: pointer;
    background: var(--neutral-color);
    transition: 0.25s ease-out;
    font-size: 1.5em;
  }

  .calendar-month .body .cell:hover {
    background: var(--bg-color);
    transition: 0.5s ease-out;
  }

  .calendar-month .body .selected {
    border-left: 10px solid transparent;
    border-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
    border-image-slice: 1;
  }
  .calendar-month .body .today {
    border-left: 10px solid transparent;
    border-image: linear-gradient(45deg, #ff1a79 0%, #eb82b3 40%);
    border-image-slice: 1;
  }
  .calendar-month .body .row {
    border-bottom: 1px solid var(--border-color);
  }

  /* .calendar-month .body .row:last-child {
    border-bottom: none;
  } */

  .calendar-month .body .cell:last-child {
    border-right: none;
  }

  .calendar-month .body .cell .number {
    position: absolute;
    font-size: 82.5%;
    line-height: 1;
    bottom: 0.15em;
    right: 0.15em;
    font-weight: 700;
  }

  .calendar-month .body .disabled {
    color: var(--text-color-light);
    pointer-events: none;
  }

  .calendar-month .body .cell .bg {
    font-weight: 700;
    line-height: 1;
    color: var(--main-color);
    opacity: 0;
    font-size: 8em;
    position: absolute;
    top: -0.2em;
    right: -0.05em;
    transition: 0.25s ease-out;
    letter-spacing: -0.07em;
  }

  .calendar-month .body .cell:hover .bg,
  .calendar-month .body .selected .bg {
    opacity: 0.05;
    transition: 0.5s ease-in;
  }
  .calendar-month .body .cell.today .bg {
    color: #ff1a79;
    opacity: 0.05;
  }

  .calendar-month .body .col {
    flex-grow: 0;
    flex-basis: calc(100% / 7);
    width: calc(100% / 7);
  }
