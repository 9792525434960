@import url(https://fonts.googleapis.com/css?family=Open + Sans:300, 400, 700);
@import url(https://fonts.googleapis.com/icon?family=Material + Icons);
@import url(https://fonts.googleapis.com/css?family=Open + Sans:300, 400, 700);
@import url(https://fonts.googleapis.com/icon?family=Material + Icons);
.App {
  font-family: sans-serif;
  text-align: center;
  min-height: 700px;
  /*background-color: paleturquoise;*/
  margin: 0;
  padding: 20px;
  width: 100%;
}
/* FONT IMPORTS */

.icon {
  font-family: "Material Icons", serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

/* VARIABLES */

:root {
  --main-color: #1a8fff;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: #f9f9f9;
  --neutral-color: #fff;
}

/* GENERAL */

* {
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  color: var(--text-color);
  background: var(--bg-color);
  position: relative;
}

header {
  display: block;
  width: 100%;
  padding: 1.75em 0;
  border-bottom: 1px solid var(--border-color);
  background: var(--neutral-color);
}

header #logo {
  font-size: 175%;
  text-align: center;
  color: var(--main-color);
  line-height: 1;
}

header #logo .icon {
  padding-right: 0.25em;
}

main {
  display: block;
  margin: 0 auto;
  margin-top: 5em;
  max-width: 50em;
}

/* GRID */
.calendar .days.row {
  width:5%;
}
.calendar .row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: calc(100vw/2);
  /*width: 5%;*/
}

.calendar .row-middle {
  align-items: center;
}

.calendar .col {
  flex-grow: 1;
  /*flex-basis: 0;*/
  max-width: 100%;
}

.calendar .col-start {
  justify-content: flex-start;
  text-align: left;
}

.calendar .col-center {
  justify-content: center;
  text-align: center;
}

.calendar .col-end {
  justify-content: flex-end;
  text-align: right;
}

/* Calendar */
.calendar .calendar-inner {
  display: block;
  position: relative;
  width: 100%;
  background: var(--neutral-color);
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: row;
}

.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: var(--neutral-color);
  border: 1px solid var(--border-color);
}

.calendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 1.5em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .header .icon {
  cursor: pointer;
  transition: 0.15s ease-out;
}

.calendar .header .icon:hover {
  /*transform: scale(1.75);*/
  transition: 0.25s ease-out;
  color: var(--main-color);
}

.calendar .header .icon:first-of-type {
  margin-left: 1em;
}

.calendar .header .icon:last-of-type {
  margin-right: 1em;
}

.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-color-light);
  font-size: 70%;
  padding: 0.75em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .cell {
  position: relative;
  height: 4em;
  border-right: 1px solid var(--border-color);
  overflow: hidden;
  cursor: pointer;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
  font-size: 1.5em;
}

.calendar .body .cell:hover {
  background: var(--bg-color);
  transition: 0.5s ease-out;
}

.calendar .body .selected {
  border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
  border-image-slice: 1;
}
.calendar .body .today {
  border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #ff1a79 0%, #eb82b3 40%);
  border-image-slice: 1;
}
.calendar .body .row {
  border-bottom: 1px solid var(--border-color);
}

/* .calendar .body .row:last-child {
  border-bottom: none;
} */

.calendar .body .cell:last-child {
  border-right: none;
}

.calendar .body .cell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: 0.75em;
  right: 0.75em;
  font-weight: 700;
}

.calendar .body .cell .emojiLeftContainer {
  line-height: 1;
  position: absolute;
  left: 0.75em;
  padding:5px;
}

.calendar .body .cell .emojiLeft {
  font-size: 60.5%;
  line-height: 1;
  left: 0.5px;
  font-weight: 400;
  display:block;
  padding:3px;
}

.calendar .body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}

.calendar .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: var(--main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -0.2em;
  right: -0.05em;
  transition: 0.25s ease-out;
  letter-spacing: -0.07em;
}

.calendar .body .cell:hover .bg,
.calendar .body .selected .bg {
  opacity: 0.05;
  transition: 0.5s ease-in;
}
.calendar .body .cell.today .bg {
  color: #ff1a79;
  opacity: 0.05;
}

.calendar .body .col {
  flex-grow: 0;
  /*flex-basis: calc(100% / 7);*/
  /*width: calc(100% / 7);*/
}

.calendar .body {
  width:100%;
}
.App {
    font-family: sans-serif;
    text-align: center;
    min-height: 700px;
    /*background-color: paleturquoise;*/
    margin: 0;
    padding: 0px;
    width: 100%;
  }
  /* FONT IMPORTS */

  .icon {
    font-family: "Material Icons", serif;
    font-style: normal;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
  }

  /* VARIABLES */

  :root {
    --main-color: #1a8fff;
    --text-color: #777;
    --text-color-light: #ccc;
    --border-color: #eee;
    --bg-color: #f9f9f9;
    --neutral-color: #fff;
  }

  /* GENERAL */

  * {
    box-sizing: border-box;
  }

  body {
    font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5;
    color: var(--text-color);
    background: var(--bg-color);
    position: relative;
  }

  header {
    display: block;
    width: 100%;
    padding: 1.75em 0;
    border-bottom: 1px solid var(--border-color);
    background: var(--neutral-color);
  }

  header #logo {
    font-size: 175%;
    text-align: center;
    color: var(--main-color);
    line-height: 1;
  }

  header #logo .icon {
    padding-right: 0.25em;
  }

  main {
    display: block;
    margin: 0 auto;
    margin-top: 5em;
    max-width: 50em;
  }

  /* GRID */

  .calendar-month .row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .calendar-month .row-middle {
    align-items: center;
  }

  .calendar-month .col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .calendar-month .col-start {
    justify-content: flex-start;
    text-align: left;
  }

  .calendar-month .col-center {
    justify-content: center;
    text-align: center;
  }

  .calendar-month .col-end {
    justify-content: flex-end;
    text-align: right;
  }

  /* Calendar */

  .calendar-month {
    display: block;
    position: relative;
    width: 100%;
    background: var(--neutral-color);
    border: 1px solid var(--border-color);
    font-size: 1em;
  }
  @media (max-width: 1280px) {
    .calendar-month {
        font-size: 0.35em;
      }
  }

  .calendar-month .header {
    /*text-transform: uppercase;*/
    font-weight: 700;
    font-size: 115%;
    padding: 1.5em 0;
    border-bottom: 1px solid var(--border-color);
  }

  .calendar-month .header .icon {
    cursor: pointer;
    transition: 0.15s ease-out;
  }

  .calendar-month .header .icon:hover {
    /*transform: scale(1.75);*/
    transition: 0.25s ease-out;
    color: var(--main-color);
  }

  .calendar-month .header .icon:first-of-type {
    margin-left: 1em;
  }

  .calendar-month .header .icon:last-of-type {
    margin-right: 1em;
  }

  .calendar-month .days {
    text-transform: uppercase;
    font-weight: 400;
    color: var(--text-color-light);
    font-size: 70%;
    padding: 0.75em 0;
    border-bottom: 1px solid var(--border-color);
  }

  .calendar-month .body .cell {
    position: relative;
    /*height: 7.5em;*/
    border-right: 1px solid var(--border-color);
    overflow: hidden;
    cursor: pointer;
    background: var(--neutral-color);
    transition: 0.25s ease-out;
    font-size: 1.5em;
  }

  .calendar-month .body .cell:hover {
    background: var(--bg-color);
    transition: 0.5s ease-out;
  }

  .calendar-month .body .selected {
    border-left: 10px solid transparent;
    border-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
    border-image-slice: 1;
  }
  .calendar-month .body .today {
    border-left: 10px solid transparent;
    border-image: linear-gradient(45deg, #ff1a79 0%, #eb82b3 40%);
    border-image-slice: 1;
  }
  .calendar-month .body .row {
    border-bottom: 1px solid var(--border-color);
  }

  /* .calendar-month .body .row:last-child {
    border-bottom: none;
  } */

  .calendar-month .body .cell:last-child {
    border-right: none;
  }

  .calendar-month .body .cell .number {
    position: absolute;
    font-size: 82.5%;
    line-height: 1;
    bottom: 0.15em;
    right: 0.15em;
    font-weight: 700;
  }

  .calendar-month .body .disabled {
    color: var(--text-color-light);
    pointer-events: none;
  }

  .calendar-month .body .cell .bg {
    font-weight: 700;
    line-height: 1;
    color: var(--main-color);
    opacity: 0;
    font-size: 8em;
    position: absolute;
    top: -0.2em;
    right: -0.05em;
    transition: 0.25s ease-out;
    letter-spacing: -0.07em;
  }

  .calendar-month .body .cell:hover .bg,
  .calendar-month .body .selected .bg {
    opacity: 0.05;
    transition: 0.5s ease-in;
  }
  .calendar-month .body .cell.today .bg {
    color: #ff1a79;
    opacity: 0.05;
  }

  .calendar-month .body .col {
    flex-grow: 0;
    flex-basis: calc(100% / 7);
    width: calc(100% / 7);
  }

.dm-container {
  display: flex;
  flex-direction: column;
  /*height: 100vh;*/
  height: 100%;
  width: 100%;
}


:is(.header, .footer) {
  background-color: #f8f9fa;
  text-align: center;
  padding: 10px;
}

.main-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-grow: 4;
  margin: 0 0;
  max-width:100%;
}

.status-text {
  /*color:orangered;*/
}

.dm-container .main-div {
  width: 100%;
  height: 100%;
  display: flex;
  /*align-items: center;?*/
  background-color: #e9ecef;
  border: 1px solid #efefef;
  /*align-items: flex-start;*/
  flex-direction: column;
  justify-content: space-between;
}

.title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #495057;
  margin: 15px auto 20px auto;
  display:flex;
}

.add-btn {
  font-size: 1rem;
  font-weight: 700;
  color: #495057;
  margin: 20px auto;
  display:flex;
  align-self:flex-end;
}


.legend-item {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
}

.legend-item > div {
  flex-basis: calc(33.33% - 10px);
  margin: 5px;
}



.alm-container {
  display: flex;
  flex-direction: column;
  /*height: 100vh;*/
  height: 100%;
  width: 100%;
}

.alm-container .main-div {
  width: 100%;
  height: 100%;
  display: flex;
  /*align-items: center;?*/
  background-color: #e9ecef;
  border: 1px solid #efefef;
  /*align-items: flex-start;*/
  flex-direction: column;

}

.taskList {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 0 10px;
  align-items: left;
}

.task{
  display: flex;
  flex-direction: row;
}
.task-notes{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 10px;
  font-size:80%;
}


.nameCircle {
  text-align: center;
  border-radius: 50%;
  background-color: rgb(7, 94, 59);
  color: white;
  font-size: 12px;
  padding: 3px;
  margin: 0 5px;
  font-style: normal;
  vertical-align: middle;
  width:24px;
  height:24px;
}

.nameCircle.sub{
  background-color: rgb(200, 112, 227);
}
