.dm-container {
  display: flex;
  flex-direction: column;
  /*height: 100vh;*/
  height: 100%;
  width: 100%;
}


:is(.header, .footer) {
  background-color: #f8f9fa;
  text-align: center;
  padding: 10px;
}

.main-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-grow: 4;
  margin: 0 0;
  max-width:100%;
}

.status-text {
  /*color:orangered;*/
}

.dm-container .main-div {
  width: 100%;
  height: 100%;
  display: flex;
  /*align-items: center;?*/
  background-color: #e9ecef;
  border: 1px solid #efefef;
  /*align-items: flex-start;*/
  flex-direction: column;
  justify-content: space-between;
}

.title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #495057;
  margin: 15px auto 20px auto;
  display:flex;
}

.add-btn {
  font-size: 1rem;
  font-weight: 700;
  color: #495057;
  margin: 20px auto;
  display:flex;
  align-self:flex-end;
}


.legend-item {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
}

.legend-item > div {
  flex-basis: calc(33.33% - 10px);
  margin: 5px;
}



.alm-container {
  display: flex;
  flex-direction: column;
  /*height: 100vh;*/
  height: 100%;
  width: 100%;
}

.alm-container .main-div {
  width: 100%;
  height: 100%;
  display: flex;
  /*align-items: center;?*/
  background-color: #e9ecef;
  border: 1px solid #efefef;
  /*align-items: flex-start;*/
  flex-direction: column;

}

.taskList {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 0 10px;
  align-items: left;
}

.task{
  display: flex;
  flex-direction: row;
}
.task-notes{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 10px;
  font-size:80%;
}


.nameCircle {
  text-align: center;
  border-radius: 50%;
  background-color: rgb(7, 94, 59);
  color: white;
  font-size: 12px;
  padding: 3px;
  margin: 0 5px;
  font-style: normal;
  vertical-align: middle;
  width:24px;
  height:24px;
}

.nameCircle.sub{
  background-color: rgb(200, 112, 227);
}